
import Vue from "vue";
import store from "@/store/index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { IStationBuffer } from "@common/station";
import _ from "lodash";
import * as XLSX from "xlsx";
import moment from "moment-timezone";

interface ExcelData {
	"Station ID": string;
	"Station Name": string;
	"Product ID": string;
	"Product Name": string;
	"Day": string;
	"Hour 00:00 - 01:00": number;
	"Hour 01:00 - 02:00": number;
	"Hour 02:00 - 03:00": number;
	"Hour 03:00 - 04:00": number;
	"Hour 04:00 - 05:00": number;
	"Hour 05:00 - 06:00": number;
	"Hour 06:00 - 07:00": number;
	"Hour 07:00 - 08:00": number;
	"Hour 08:00 - 09:00": number;
	"Hour 09:00 - 10:00": number;
	"Hour 10:00 - 11:00": number;
	"Hour 11:00 - 12:00": number;
	"Hour 12:00 - 13:00": number;
	"Hour 13:00 - 14:00": number;
	"Hour 14:00 - 15:00": number;
	"Hour 15:00 - 16:00": number;
	"Hour 16:00 - 17:00": number;
	"Hour 17:00 - 18:00": number;
	"Hour 18:00 - 19:00": number;
	"Hour 19:00 - 20:00": number;
	"Hour 20:00 - 21:00": number;
	"Hour 21:00 - 22:00": number;
	"Hour 22:00 - 23:00": number;
	"Hour 23:00 - 00:00": number;
}

function isValidExcelData (data: ExcelData): boolean {
	const hasStationId = data["Station ID"] !== undefined;
	const hasStationName = data["Station Name"] !== undefined;
	const hasProductId = data["Product ID"] !== undefined;
	const hasProductName = data["Product Name"] !== undefined;
	const hasDay = data.Day !== undefined;
	// eslint-disable-next-line
	// @ts-ignore
	const hasHours = _.every(_.range(0, 24), n => data[`Hour ${_.padStart(n.toString(), 2, "0")}:00 - ${_.padStart(((n + 1) % 24).toString(), 2, "0")}:00`] !== undefined);

	return hasStationId && hasStationName && hasProductId && hasProductName && hasDay && hasHours;
}

export default Vue.extend({
	name: "BsoTab",
	props: {
		stationId: String,
	},
	data () {
		const snackbar = {
			show: false,
			text: "",
			color: "primary",
		};

		return {
			rawData: [] as IStationBuffer[],
			wholeTable: [] as (IStationBuffer & {productName?: string})[],
			snackbar,
			filters: {
				products: [] as string[],
				weekday: 1
			},
			autoCompleteChipsLimit: 10,
			loading: false,
			loseDataModalVisible: false,
			loseDataModalData: 1,
			watcherWeekdayActive: true,
			fileUploaded: null as File | null,
		};
	},
	created: async function () {
		if (this.store.getters.products.length === 0) {
			await this.store.dispatch.fetchProducts();
		}

		this.filters.products = [this.productSelectItems[0].value];

		this.fetchData();
	},
	methods: {
		async importData () {
			this.loading = true;
			let fileToUpload: string | ArrayBuffer | null = null;

			const fileReader = new FileReader();
			fileReader.addEventListener("load", () => {
				fileToUpload = fileReader.result;
			});

			if (this.fileUploaded) {
				fileReader.readAsArrayBuffer(this.fileUploaded);
			}

			fileReader.addEventListener("loadend", () => {
				if (fileToUpload) {
					this.xlsxToData(fileToUpload as ArrayBuffer);
				} else {
					this.loading = false;
				}
			});
		},
		async xlsxToData (bytes: ArrayBuffer) {
			const workbook = XLSX.read(bytes, { type: "array" });
			const sheet = workbook.Sheets.BSO;

			if (!sheet) {
				this.loading = false;
				return;
			}

			const data: ExcelData[] = XLSX.utils.sheet_to_json(sheet);
			const dataToSave: IStationBuffer[] = [];

			for (const item of data) {
				if (isValidExcelData(item)) {
					dataToSave.push({
						stationId: item["Station ID"],
						productId: item["Product ID"],
						day: moment(item.Day, "dddd").isoWeekday(),
						// eslint-disable-next-line
						// @ts-ignore
						buffer: _.map(_.range(0, 24), n => item[`Hour ${_.padStart(n.toString(), 2, "0")}:00 - ${_.padStart(((n + 1) % 24).toString(), 2, "0")}:00`])
					});
				}
			}

			try {
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/station/buffer`,
					data: dataToSave
				};

				const res: AxiosResponse<IServerRES<boolean>> = await axios(options);

				if (res.data.err === ServerError.NO_ERROR) {
					this.snackbar = {
						show: true,
						text: "BSO saved successfully!",
						color: "success",
					};
				} else {
					this.snackbar = {
						show: true,
						text: "Failed to save BSO!",
						color: "error",
					};
				}
			} catch (err) {
				console.error(err);

				this.snackbar = {
					show: true,
					text: "Failed to save BSO!",
					color: "error",
				};
			}

			setTimeout(async () => {
				await this.fetchData();
				this.loading = false;
			}, 300);
		},
		onFileChange (event: Event) {
			const target = event.target as HTMLInputElement;
			const file = target.files?.[0];

			if (file) {
				this.fileUploaded = file;

				this.importData();
			}
		},
		onPickFile () {
			if (this.$refs.fileInput) {
				(this.$refs.fileInput as HTMLInputElement).click();
			}
		},
		async exportExcel () {
			this.loading = true;
			const days = this.weekdays.map(w => w.id);
			const data: ExcelData[] = [];

			if (this.store.getters.stations.length === 0) {
				await this.store.dispatch.fetchStations();
			}

			const stationName = this.store.getters.stations.find(s => s.id === this.stationId)?.name;
			if (!stationName) {
				this.loading = false;
				return;
			}

			if (this.store.getters.products.length === 0) {
				await this.store.dispatch.fetchProducts();
			}
			const products = this.store.getters.products;

			for (const day of days) {
				try {
					const options: AxiosRequestConfig = {
						method: "GET",
						headers: {
							Authorization: `Bearer ${localStorage.getItem("token")}`
						},
						url: `${store.getters.serverURL}/station/buffer/${this.stationId}/${day}`,
					};

					const res: AxiosResponse<IServerRES<IStationBuffer[]>> = await axios(options);

					if (res.data.err === ServerError.NO_ERROR) {
						res.data.payload.forEach(item => {
							if (item.productId !== null) {
								const productName = products.find(p => p.id === item.productId)?.title;
								if (productName === undefined) {
									return;
								}

								data.push({
									"Station ID": this.stationId,
									"Station Name": stationName,
									"Product ID": item.productId ? item.productId : "",
									"Product Name": productName,
									Day: moment().isoWeekday(day).format("dddd"),
									"Hour 00:00 - 01:00": item.buffer[0],
									"Hour 01:00 - 02:00": item.buffer[1],
									"Hour 02:00 - 03:00": item.buffer[2],
									"Hour 03:00 - 04:00": item.buffer[3],
									"Hour 04:00 - 05:00": item.buffer[4],
									"Hour 05:00 - 06:00": item.buffer[5],
									"Hour 06:00 - 07:00": item.buffer[6],
									"Hour 07:00 - 08:00": item.buffer[7],
									"Hour 08:00 - 09:00": item.buffer[8],
									"Hour 09:00 - 10:00": item.buffer[9],
									"Hour 10:00 - 11:00": item.buffer[10],
									"Hour 11:00 - 12:00": item.buffer[11],
									"Hour 12:00 - 13:00": item.buffer[12],
									"Hour 13:00 - 14:00": item.buffer[13],
									"Hour 14:00 - 15:00": item.buffer[14],
									"Hour 15:00 - 16:00": item.buffer[15],
									"Hour 16:00 - 17:00": item.buffer[16],
									"Hour 17:00 - 18:00": item.buffer[17],
									"Hour 18:00 - 19:00": item.buffer[18],
									"Hour 19:00 - 20:00": item.buffer[19],
									"Hour 20:00 - 21:00": item.buffer[20],
									"Hour 21:00 - 22:00": item.buffer[21],
									"Hour 22:00 - 23:00": item.buffer[22],
									"Hour 23:00 - 00:00": item.buffer[23],
								});
							}
						});
					}
				} catch (err) {
					console.error(err);
				}
			}

			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(data);
			XLSX.utils.book_append_sheet(wb, ws, "BSO");
			XLSX.writeFile(wb, "BSO.xlsx");

			this.loading = false;
		},
		async fetchData () {
			this.loading = true;

			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/station/buffer/${this.stationId}/${this.filters.weekday}`,
				};

				const res: AxiosResponse<IServerRES<IStationBuffer[]>> = await axios(options);

				if (res.data.err === ServerError.NO_ERROR) {
					this.rawData = res.data.payload;
				}
			} catch (err) {
				console.error(err);
			}

			this.dbDataToTable();

			setTimeout(() => {
				this.loading = false;
			}, 300);
		},
		dbDataToTable () {
			this.wholeTable = _.map(
				_.filter(this.rawData, entry => !!_.find(this.filters.products, p => p === entry.productId)),
				item => ({
					...item,
					buffer: [...item.buffer], // for having a new array, not the same reference
					productName: _.find(this.productSelectItems, p => p.value === item.productId)?.text
				})
			);
		},
		getChangesInData () {
			const final: IStationBuffer[] = [];
			_.forEach(this.wholeTable, item => {
				const dbItem = _.find(this.rawData, entry => entry.productId === item.productId);
				if (dbItem) {
					if (!_.isEqual(dbItem.buffer, item.buffer)) {
						final.push({
							stationId: this.stationId,
							productId: item.productId,
							day: item.day,
							buffer: item.buffer
						});
					}
				}
			});
			return final;
		},
		async save () {
			this.loading = true;

			const data: IStationBuffer[] = this.getChangesInData();

			try {
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/station/buffer`,
					data
				};

				const res: AxiosResponse<IServerRES<boolean>> = await axios(options);

				if (res.data.err === ServerError.NO_ERROR) {
					this.snackbar = {
						show: true,
						text: "BSO saved successfully!",
						color: "success",
					};
				} else {
					this.snackbar = {
						show: true,
						text: "Failed to save BSO!",
						color: "error",
					};
				}
			} catch (err) {
				console.error(err);

				this.snackbar = {
					show: true,
					text: "Failed to save BSO!",
					color: "error",
				};
			}

			setTimeout(() => {
				this.loading = false;
			}, 300);
		},
		toggleSelectAllProducts () {
			if (this.filters.products.length === this.productSelectItems.length) {
				this.filters.products = [];
			}	else {
				for (const item of this.productSelectItems) {
					if (this.filters.products.indexOf(item.value) === -1) {
						this.filters.products.push(item.value);
					}
				}
			}
		},
	},
	computed: {
		headers () {
			let finalHeaders = [{ text: "Products", align: "start" }];
			finalHeaders = finalHeaders.concat(_.map(_.range(0, 24), n => ({
				text: (_.padStart(n.toString(), 2, "0") + ":00") + "\n" + (_.padStart(((n + 1) % 24).toString(), 2, "0") + ":00"),
				align: "center"
			})));
			return finalHeaders;
		},
		weekdays () {
			return [
				{ id: 1, text: "Monday" },
				{ id: 2, text: "Tuesday" },
				{ id: 3, text: "Wednesday" },
				{ id: 4, text: "Thursday" },
				{ id: 5, text: "Friday" },
				{ id: 6, text: "Saturday" },
				{ id: 7, text: "Sunday" }
			];
		},
		productSelectItems () {
			const productSelectItems: {
				text: string;
				value: string;
			} [] = [];

			for (const product of this.store.getters.products) {
				const item = {
					text: `(${product.id}) ${product.title}`,
					value: product.id
				};

				if (_.findIndex(productSelectItems, item) === -1) {
					productSelectItems.push(item);
				}
			}

			return productSelectItems;
		},
		icon () {
			if (this.filters.products.length === this.productSelectItems.length) return "mdi-close-box";
			if (this.filters.products.length !== this.productSelectItems.length && this.filters.products.length !== 0) return "mdi-minus-box";
			return "mdi-checkbox-blank-outline";
		},
		hoursOfDay () {
			return _.range(0, 24);
		}
	},
	watch: {
		stationId: function () {
			this.fetchData();
		}
	}
});
